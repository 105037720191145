// Generated by Framer (1d71865)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { CircularProgressBar } from "https://framerusercontent.com/modules/4AmwC9UTL9LzavRiGHzW/405gnlfW5zM3JGzJw8UV/Circular.js";
const CircularProgressBarFonts = getFonts(CircularProgressBar);

const cycleOrder = ["PhJ7kKhxP"];

const serializationHash = "framer-QcXyd"

const variantClassNames = {PhJ7kKhxP: "framer-v-1oidk65"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "PhJ7kKhxP", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1oidk65", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"PhJ7kKhxP"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-15so54d-container"} layoutDependency={layoutDependency} layoutId={"GoU8_uah1-container"}><CircularProgressBar cornerRadius={false} duration={2} endPercentage={100} height={"100%"} id={"GoU8_uah1"} layoutId={"GoU8_uah1"} progressColor={"var(--token-b640c7c2-3594-4dda-8605-02a1a2a6138b, rgb(0, 194, 42))"} replay={false} strokeWidth={6} style={{height: "100%", width: "100%"}} trackColor={"var(--token-a21c5608-6f6d-44b8-a4dd-3ec4d069f1f6, rgb(250, 250, 250))"} width={"100%"}/></motion.div></ComponentViewportProvider><ComponentViewportProvider ><motion.div className={"framer-1az2ac5-container"} layoutDependency={layoutDependency} layoutId={"mR8b_n09H-container"}><CircularProgressBar cornerRadius duration={10} endPercentage={100} height={"100%"} id={"mR8b_n09H"} layoutId={"mR8b_n09H"} progressColor={"var(--token-62d426ff-42b5-4626-907b-1c53ee5312f1, rgb(255, 174, 0))"} replay={false} strokeWidth={16} style={{height: "100%", width: "100%"}} trackColor={"var(--token-a21c5608-6f6d-44b8-a4dd-3ec4d069f1f6, rgb(250, 250, 250))"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QcXyd.framer-141rk98, .framer-QcXyd .framer-141rk98 { display: block; }", ".framer-QcXyd.framer-1oidk65 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-QcXyd .framer-15so54d-container { flex: none; height: 120px; position: relative; width: 96px; }", ".framer-QcXyd .framer-1az2ac5-container { flex: none; height: 209px; position: relative; width: 209px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-QcXyd.framer-1oidk65 { gap: 0px; } .framer-QcXyd.framer-1oidk65 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-QcXyd.framer-1oidk65 > :first-child { margin-left: 0px; } .framer-QcXyd.framer-1oidk65 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 209
 * @framerIntrinsicWidth 315
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerFqOK4b4LD: React.ComponentType<Props> = withCSS(Component, css, "framer-QcXyd") as typeof Component;
export default FramerFqOK4b4LD;

FramerFqOK4b4LD.displayName = "Code/Circular Progress Container";

FramerFqOK4b4LD.defaultProps = {height: 209, width: 315};

addFonts(FramerFqOK4b4LD, [{explicitInter: true, fonts: []}, ...CircularProgressBarFonts], {supportsExplicitInterCodegen: true})